import axios from "axios";

// <--Offers API -->

export const getallOffer = async () => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/offer/getalloffer`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleOffer = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/offer/getsingleoffer/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

// <--FAQ API -->

export const createFaq = async (formData) => {
  try {
    const response = await axios.post(
      "https://api.exploretriponline.com/faq/create",
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating testimonial:", error);
    throw error;
  }
};

export const updateFaq = async (faq_id, formData) => {
  try {
    const response = await axios.put(
      `https://api.exploretriponline.com/faq/updatesinglefaq/${faq_id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

export const getallFaq = async () => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/faq/getallfaq`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleFaq = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/faq/getsinglefaq/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleFaq = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/faq/deletesinglefaq/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

// <-- Top Tourist Destination Locations API -->

export const createTouristDestination = async (formData) => {
  console.log(formData);
  try {
    const response = await axios.post(
      "https://api.exploretriponline.com/touristdestination/createtouristdestination",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating tourist destination:", error);
    throw error;
  }
};

export const getalltouristdestination = async () => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/touristdestination/getalltouristdestination`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSingleTouristDestination = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/touristdestination/getsingletouristdestination/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleTouristDestination = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/touristdestination/deletesingletouristdestination/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateSingleTouristDestination = async (
  tourist_destination_id,
  formData
) => {
  try {
    const response = await axios.put(
      `https://api.exploretriponline.com/touristdestination/updatesingletouristdestination/${tourist_destination_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

// <-- Daily Travel Blog API -->

export const createTravelBlogs = async (formData) => {
  try {
    const response = await axios.post(
      "https://api.exploretriponline.com/travelblogs/createtravelblog",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating Travel Blog:", error);
    throw error;
  }
};

export const GetAllTravelBlogs = async () => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/travelblogs/getalltravelblogs`,
      {
        method: "Post",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSingleTravelBlogs = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/travelblogs/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleTravelBlogs = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/travelblogs/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateSingleTravelBlogs = async (
  updateTravelBlogsData_id,
  formData
) => {
  try {
    const response = await axios.put(
      `https://api.exploretriponline.com/travelblogs/${updateTravelBlogsData_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

// <-- Testimonials API -->

export const createTestimonial = async (formData) => {
  try {
    const response = await axios.post(
      "https://api.exploretriponline.com/testimonials/createtestimonial",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating testimonial:", error);
    throw error;
  }
};

export const GetAllTestimonials = async () => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/testimonials/getalltestimonials`,
      {
        method: "Post",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSingleTestimonials = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/testimonials/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleTestimonials = async (id) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/testimonials/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateSingleTestimonials = async (
  updateTestimonialData_id,
  formData
) => {
  try {
    const response = await axios.put(
      `https://api.exploretriponline.com/testimonials/${updateTestimonialData_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

// Holiday Packages

export const getallHolidayPackages = async () => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/holidaypackage/getallholidaypackage`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSingleHolidayPackages = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/holidaypackage/getsingleholidaypackage/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleHolidayPackages = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/holidaypackage/deletesingleholidaypackage/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

// User Data

export const getallUserData = async () => {
  try {
    const res = await fetch(`http://localhost:9090/user/allUser`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteSingleUserData = async (id) => {
  try {
    const res = await fetch(
      `https://api.exploretriponline.com/userAuth/deleteuserdata/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateSingleUserStatus = async (mobile, status) => {
  try {
    const res = await fetch(`https://api.exploretriponline.com/userAuth/updateuserstatus`, {
      method: "PUT", // Corrected the method to uppercase 'PUT'
      headers: {
        "Content-Type": "application/json", // Corrected the header key
      },
      body: JSON.stringify({ mobile, status }), // Corrected: JSON.stringify for the body
    });

    // Check if the response is OK (status 200-299)
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    console.error("Error updating user status:", error);
    throw error; // Rethrow the error for further handling if needed
  }
};

// Orders

export const getallOrdersData = async () => {
  try {
    const res = await fetch(`http://localhost:9080/hotelorders/getallhotelorders`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};


// Cabs Orders

export const getallCabsOrdersData = async () => {
  try {
    const res = await fetch(`http://localhost:9080/cabsorders/getallcaborders`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

