import React, { useEffect, useState } from "react";
import { getSingleBanners, updateBanners } from "../api/HomePageBannersApi";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";

export const UpdateBanner = () => {
  const { bannerId } = useParams();
  const [BannerDetails, SetBannerDetails] = useState();

  const getSingleProductDetails = async () => {
    try {
      const response = await getSingleBanners(bannerId);
      if (response?.status === "success") {
        SetBannerDetails(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProductDetails();
  }, []);

  const HomePageBannerSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    titleSlug: Yup.string()
      .matches(
        /^[a-z-]+$/,
        "Slug can only contain lowercase letters and dashes (-), numbers and special characters are not allowed"
      )
      .required("Title Name Slug is required"),
    BannerImage: Yup.mixed(),
    type: Yup.string()
      .oneOf(["homepage", "ads"], "Invalid Type")
      .required("Type is required"),
    platform: Yup.string()
      .oneOf(["mobile", "desktop"], "Invalid Platform")
      .required("Platform is required"),
    status: Yup.string()
      .oneOf(["active", "inactive"], "Invalid Status")
      .required("Status is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("titleSlug", values.titleSlug);
    formData.append("type", values.type);
    formData.append("platform", values.platform);
    formData.append("status", values.status);

    // Conditionally append image only if it's a new upload
    if (values.BannerImage && typeof values.BannerImage !== "string") {
      formData.append("BannerImage", values.BannerImage);
    } else {
      formData.append("BannerImage", BannerDetails?.BannerImage);
    }

    try {
      const res = await updateBanners(bannerId, formData);
      if (res?.data?.status === "success") {
        toast.success("Banners Updated Successfully!", { duration: 3000 });
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        <div>Update Banners</div>

        <div className="row">
          <Toaster position="top-right" reverseOrder={false} />

          <Formik
            enableReinitialize
            initialValues={{
              title: BannerDetails?.title || "",
              titleSlug: BannerDetails?.titleSlug || "",
              type: BannerDetails?.type || "",
              platform: BannerDetails?.platform || "",
              status: BannerDetails?.status || "",
              BannerImage: BannerDetails?.BannerImage || "",
            }}
            validationSchema={HomePageBannerSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 Form_Field pe-3 p-0">
                    <div className="Form_Field_Heading">
                      Title<span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="title"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field pe-3 p-0">
                    <div className="Form_Field_Heading">
                      Title Slug<span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="titleSlug"
                      className="Form_Field_Input"
                      onChange={(e) => {
                        const slug = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z-]/g, "")
                          .replace(/-+/g, "-");
                        setFieldValue("titleSlug", slug);
                      }}
                    />
                    <ErrorMessage
                      name="titleSlug"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Banner Image<span>*</span>
                    </div>
                    <input
                      type="file"
                      name="BannerImage"
                      onChange={(event) => {
                        setFieldValue(
                          "BannerImage",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    {values.BannerImage &&
                      !(values.BannerImage instanceof File) && (
                        <img
                          src={values.BannerImage}
                          alt="Current Banner"
                          style={{ width: 200 }}
                        />
                      )}
                    <ErrorMessage
                      name="BannerImage"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  {/* Other form fields */}
                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Type<span>*</span>
                    </div>
                    <Field as="select" name="type" className="Form_Field_Input">
                      <option value="">Select Type</option>
                      <option value="homepage">Homepage Banner</option>
                      <option value="ads">Ads Banner</option>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Platform<span>*</span>
                    </div>
                    <Field
                      as="select"
                      name="platform"
                      className="Form_Field_Input"
                    >
                      <option value="">Select Platform</option>
                      <option value="mobile">Mobile</option>
                      <option value="desktop">Desktop</option>
                    </Field>
                    <ErrorMessage
                      name="platform"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Status<span>*</span>
                    </div>
                    <Field
                      as="select"
                      name="status"
                      className="Form_Field_Input"
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="Form_Field_Submit_Button">
                  <button type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
