import React, { useEffect, useState } from "react";
import { getallAboutUsOverView, getallAwards } from "../api/AboutUsApi";
import Table from "react-bootstrap/Table";
import { Toaster } from "react-hot-toast";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url";

export const Aboutus = () => {
  const [AboutUsOverViewData, setAboutUsOverViewData] = useState([]);

  const FetchAllCategory = async () => {
    try {
      const res = await getallAboutUsOverView();
      console.log(res);
      if (res?.status === "success") {
        setAboutUsOverViewData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllCategory();
  }, []);

  const handleSingleCategory = async () => {};
  const handleUpdateCategory = async () => {};
  const handleDeleteCategory = async () => {};

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                About us Management
              </span>
            </div>
          </div>
        </div>

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Overview Title</th>
                <th>Overview Description</th>
                <th>Overview Image</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(AboutUsOverViewData) &&
              AboutUsOverViewData.length > 0 ? (
                AboutUsOverViewData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>
                      <td>{ele?.OverviewTitle}</td>
                      <td>
                        {ele?.OverviewDescription?.map((ele, index) => {
                          return (
                            <div>
                              <ul>
                                <li>
                                  {ele?.length > 10
                                    ? ele?.slice(0, 30) + "..."
                                    : ele}
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </td>

                      <td>
                        <img
                          src={`${Base_Url}${ele?.OverviewImage}`}
                          alt=""
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>
                        <td>
                          <div className="d-flex">
                            <div
                              onClick={() => handleSingleCategory(ele)}
                              className="action_button_view"
                            >
                              <IoEye />
                            </div>
                            <div
                              className="px-2 action_button_update"
                              onClick={() => handleUpdateCategory(ele)}
                            >
                              <HiPencilSquare className="text-success" />
                            </div>
                            <div
                              className="action_button_delete"
                              onClick={() => handleDeleteCategory(ele)}
                            >
                              <IoTrashBinOutline className="text-danger" />
                            </div>
                          </div>
                        </td>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
