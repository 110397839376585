import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { GrFormAdd } from "react-icons/gr";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import * as Yup from "yup";
import {
  createBanners,
  DeleteSingleBanner,
  getAllBannersApi,
} from "../api/HomePageBannersApi";
import { Base_Url } from "../constant/Url";
import { useNavigate } from "react-router-dom";

export const HomePageBanner = () => {
  const [BannersData, SetBannersData] = useState();

  const navigate = useNavigate();

  const HomePageBannerSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),

    titleSlug: Yup.string()
      .matches(
        /^[a-z-]+$/, // No numbers, spaces, or special characters except dashes
        "Slug can only contain lowercase letters and dashes (-), numbers and special characters are not allowed"
      )
      .required("Title Name Slug is required"),

    BannerImage: Yup.mixed().required("Banner Image is required"),
    type: Yup.string()
      .oneOf(["homepage", "ads"], "Invalid Type")
      .required("Type is required"),
    platform: Yup.string()
      .oneOf(["mobile", "desktop"], "Invalid Platform")
      .required("Platform is required"),
    status: Yup.string()
      .oneOf(["active", "inactive"], "Invalid Status")
      .required("Status is required"),
  });

  const FetchAllBanners = async () => {
    try {
      const response = await getAllBannersApi();
      console.log(response, "getAllBannersApi");
      if (response?.status === "success") {
        SetBannersData(response?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("titleSlug", values.titleSlug);
    formData.append("type", values.type);
    formData.append("platform", values.platform);
    formData.append("status", values.status);
    formData.append("BannerImage", values.BannerImage);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createBanners(formData);
      console.log(res, "createBanners");
      if (res?.data?.status === "success") {
        toast.success("Banners Added Successfully!", { duration: 3000 });
        FetchAllBanners();
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteBanner = async (ele) => {
    try {
      console.log(ele, "handleDeleteBanner");
      const res = await DeleteSingleBanner(ele?._id);
      if (res?.status === "success") {
        toast.success("Banner Deleted Successfully!", { duration: "3000" });
        FetchAllBanners();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateBanners = (data) => {
    navigate(`/homepagebanner/update/${data?._id}`);
  };

  useEffect(() => {
    FetchAllBanners();
  }, []);

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Home Page Banner Management
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <Formik
            initialValues={{
              title: "",
              titleSlug: "",
              BannerImage: null,
              type: "",
              platform: "",
              status: "",
            }}
            validationSchema={HomePageBannerSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 Form_Field pe-3 p-0">
                    <div className="Form_Field_Heading">
                      Title<span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="title"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field pe-3 p-0">
                    <div className="Form_Field_Heading">
                      Title Slug<span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="titleSlug"
                      className="Form_Field_Input"
                      onChange={(e) => {
                        const slug = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z-]/g, "")
                          .replace(/-+/g, "-");
                        setFieldValue("titleSlug", slug);
                      }}
                    />
                    <ErrorMessage
                      name="titleSlug"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Banner Image<span>*</span>
                    </div>
                    <input
                      type="file"
                      name="BannerImage"
                      onChange={(event) => {
                        setFieldValue(
                          "BannerImage",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <ErrorMessage
                      name="BannerImage"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Type<span>*</span>
                    </div>
                    <Field as="select" name="type" className="Form_Field_Input">
                      <option value="">Select Type</option>
                      <option value="homepage">Homepage Banner</option>
                      <option value="ads">Ads Banner</option>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Platform<span>*</span>
                    </div>
                    <Field
                      as="select"
                      name="platform"
                      className="Form_Field_Input"
                    >
                      <option value="">Select Platform</option>
                      <option value="mobile">Mobile</option>
                      <option value="desktop">Desktop</option>
                    </Field>
                    <ErrorMessage
                      name="platform"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Status<span>*</span>
                    </div>
                    <Field
                      as="select"
                      name="status"
                      className="Form_Field_Input"
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="Form_Field_Submit_Button">
                  <button type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="row pt-4">
          <div className="row p-2 table_user_data" style={{ overflow: "auto" }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Title</th>
                  <th>Banner Image</th>
                  <th>Title Slug</th>
                  <th>Banner Type</th>
                  <th>Platform Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(BannersData) && BannersData.length > 0 ? (
                  BannersData?.map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>

                      <td>{ele?.title}</td>

                      <td>
                        <div key={index} className="img_box_pro_dt">
                          <img
                            src={`${Base_Url}${ele?.BannerImage}`}
                            alt=""
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "80px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </td>

                      <td>{ele?.titleSlug}</td>
                      <td>{ele?.type}</td>
                      <td>{ele?.platform}</td>
                      <td>{ele?.status}</td>

                      <td>
                        <td>
                          <div className="d-flex">
                            <div
                              className="px-2 action_button_update"
                              onClick={() => handleUpdateBanners(ele)}
                            >
                              <HiPencilSquare className="text-success" />
                            </div>
                            <div
                              className="action_button_delete"
                              onClick={() => handleDeleteBanner(ele)}
                            >
                              <IoTrashBinOutline className="text-danger" />
                            </div>
                          </div>
                        </td>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center">
                      Data Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
