import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSingleProducts } from "../api/ProductsApi";

export const UpdateProduct = () => {
  const { productId } = useParams();

  const getSingleProductDetails = async () => {
    try {
      const response = await getSingleProducts(productId);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProductDetails();
  }, []);

  return (
    <div>
      <h1>Update Product</h1>
      <p>Updating product with ID: {productId}</p>
      {/* Add your form and logic for updating the product */}
    </div>
  );
};
