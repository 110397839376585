import axios from "axios";
import { Base_Url } from "../constant/Url";

export const createProduct = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/product/createproduct`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating Product", error);
    throw error;
  }
};

export const getallProducts = async () => {
  try {
    const res = await fetch(`${Base_Url}/product/getallproduct`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleProducts = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/product/getsingleProduct/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleProduct = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/product/deletesingleproduct/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleProducts = async (product_id, formData) => {
  try {
    const response = await axios.put(
      `${Base_Url}/product/updatesingleproduct/${product_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating Our Brands:", error);
    throw error;
  }
};
