import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url";
import {
  createOnlineStore,
  DeleteSingleOnlineStore,
  getAllOnlineStore,
  GetSingleOnlineStore,
  UpdateSingleOnlineStore,
} from "../api/OnlineStoreApi";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Toaster, toast } from "react-hot-toast";
import { GrFormAdd } from "react-icons/gr";

export const OnlineStore = () => {
  const [OnlineStoreData, setOnlineStoreData] = useState([]);
  const [SingleOnlineStoreData, setSingleOnlineStoreData] = useState([]);

  const [
    showSingleOnlineStoreDetailsModal,
    setShowSingleOnlineStoreDetailsModal,
  ] = useState(false);

  const [
    showUpdateOnlineStoreDetailsModal,
    setShowUpdateOnlineStoreDetailsModal,
  ] = useState(false);

  const [
    showCreateOnlineStoreDetailsModal,
    setShowCreateOnlineStoreDetailsModal,
  ] = useState(false);

  const handleSingleOnlineStoreDetailsModalClose = () =>
    setShowSingleOnlineStoreDetailsModal(false);
  const handleSingleOnlineStoreDetailsModalShow = () =>
    setShowSingleOnlineStoreDetailsModal(true);

  const handleUpdateOnlineStoreDetailsModalClose = () =>
    setShowUpdateOnlineStoreDetailsModal(false);
  const handleUpdateOnlineStoreDetailsModalShow = () =>
    setShowUpdateOnlineStoreDetailsModal(true);

  const handleCreateOnlineStoreDetailsModalClose = () =>
    setShowCreateOnlineStoreDetailsModal(false);
  const handleCreateOnlineStoreDetailsModalShow = () =>
    setShowCreateOnlineStoreDetailsModal(true);

  const FetchAllOnlineStore = async () => {
    try {
      const res = await getAllOnlineStore();
      console.log(res);
      if (res?.status === "success") {
        setOnlineStoreData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllOnlineStore();
  }, []);

  const handleSingleOnlineStore = async (ele) => {
    try {
      const res = await GetSingleOnlineStore(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        handleSingleOnlineStoreDetailsModalShow();
        setSingleOnlineStoreData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOnlineStore = async (ele) => {
    try {
      const res = await GetSingleOnlineStore(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        handleUpdateOnlineStoreDetailsModalShow();
        setSingleOnlineStoreData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("OnlineStoreName", values.OnlineStoreName);
    formData.append("OnlineStoreUrl", values.OnlineStoreUrl);
    if (values.OnlineStoreImage) {
      formData.append("OnlineStoreImage", values.OnlineStoreImage);
    } else {
      formData.append(
        "OnlineStoreImage",
        SingleOnlineStoreData.OnlineStoreImage
      );
    }

    try {
      const res = await UpdateSingleOnlineStore(
        SingleOnlineStoreData._id,
        formData
      );
      console.log(res);
      if (res?.data?.status === "success") {
        setOnlineStoreData((prev) =>
          prev.map((item) =>
            item._id === SingleOnlineStoreData._id
              ? {
                  ...item,
                  OnlineStoreName: values?.OnlineStoreName,
                  OnlineStoreUrl: values?.OnlineStoreUrl,
                  OnlineStoreImage: res.data?.OnlineStoreImage, // Assuming your backend returns the updated image path
                }
              : item
          )
        );
        toast.success("Testimonial Updated Successfully!");
        handleUpdateOnlineStoreDetailsModalClose();
      }
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitCreateOnlineStore = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("OnlineStoreName", values.OnlineStoreName);
    formData.append("OnlineStoreUrl", values.OnlineStoreUrl);
    formData.append("OnlineStoreImage", values.OnlineStoreImage);

    try {
      const res = await createOnlineStore(formData);
      console.log(res);

      if (res?.data?.status === "success") {
        const newStore = res.data.newStore; // Make sure 'newStore' contains all the fields
        toast.success("Online Store Added Successfully!");
        handleCreateOnlineStoreDetailsModalClose();
        setOnlineStoreData((prev) => [...prev, newStore]);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating online store:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteCategory = async (ele) => {
    try {
      const res = await DeleteSingleOnlineStore(ele?._id);
      if (res?.status === "success") {
        toast.success("Online Store Deleted Successfully!");
        setOnlineStoreData(
          OnlineStoreData.filter((item) => item._id !== ele._id)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OnlineStoreSchema = Yup.object().shape({
    OnlineStoreName: Yup.string().required("OnlineStoreName is required"),
    OnlineStoreImage: Yup.string().required("OnlineStoreImage URL is required"),
    OnlineStoreUrl: Yup.string().required("OnlineStoreUrl is required"),
  });

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Online Store Data Management
            </span>
          </div>
          <div>
            <button
              className="addbutton"
              onClick={handleCreateOnlineStoreDetailsModalShow}
            >
              Add Online Store{" "}
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
          </div>
        </div>
      </div>

      {/* Table - All Online Store */}

      <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Online Store Name</th>
              <th>Online Store Url</th>
              <th>Online Store Image</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(OnlineStoreData) && OnlineStoreData.length > 0 ? (
              OnlineStoreData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                .map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.OnlineStoreName}</td>
                    <td>
                      <NavLink to={ele?.OnlineStoreUrl}>
                        {ele?.OnlineStoreUrl}
                      </NavLink>
                    </td>
                    <td>
                      <img
                        src={`${Base_Url}${ele?.OnlineStoreImage}`}
                        alt=""
                        style={{ width: "100%", height: "100px" }}
                      />
                    </td>

                    <td>
                      <td>
                        <div className="d-flex">
                          <div
                            onClick={() => handleSingleOnlineStore(ele)}
                            className="action_button_view"
                          >
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateOnlineStore(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteCategory(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* View Single Online Store */}

      <div className="row">
        <Modal
          show={showSingleOnlineStoreDetailsModal}
          onHide={handleSingleOnlineStoreDetailsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Single Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="modaldata d-flex pb-3">
                <span>OnlineStoreName : </span>
                <div>{SingleOnlineStoreData?.OnlineStoreName}</div>
              </div>
              <div className="modaldata d-flex pb-3">
                <span>OnlineStoreUrl : </span>

                <NavLink to={SingleOnlineStoreData?.OnlineStoreUrl}>
                  {SingleOnlineStoreData?.OnlineStoreUrl}
                </NavLink>
              </div>

              <div className="modaldata d-flex pb-3">
                <span>Product Image : </span>
                <div>
                  <img
                    src={`${Base_Url}${SingleOnlineStoreData?.OnlineStoreImage}`}
                    alt=""
                    style={{ width: "100%", height: "50px" }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update Online Store */}

      <div className="row">
        <Modal
          show={showUpdateOnlineStoreDetailsModal}
          onHide={handleUpdateOnlineStoreDetailsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {SingleOnlineStoreData && (
                <Formik
                  initialValues={{
                    OnlineStoreName:
                      SingleOnlineStoreData.OnlineStoreName || "",
                    OnlineStoreImage:
                      SingleOnlineStoreData.OnlineStoreImage || null,
                    OnlineStoreUrl: SingleOnlineStoreData.OnlineStoreUrl || "",
                  }}
                  validationSchema={OnlineStoreSchema}
                  onSubmit={handleSubmitUpdate}
                >
                  {({ setFieldValue, values, isSubmitting }) => (
                    <Form>
                      <div>
                        <div>Online Store Name</div>
                        <Field
                          type="text"
                          name="OnlineStoreName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="OnlineStoreName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div>
                        <div>Online Store Url</div>
                        <Field
                          type="text"
                          name="OnlineStoreUrl"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="OnlineStoreUrl"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mt-2">
                        <div>Online Store Image</div>
                        <input
                          type="file"
                          name="OnlineStoreImage"
                          className="Form_Field"
                          onChange={(event) => {
                            setFieldValue(
                              "OnlineStoreImage",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleUpdateOnlineStoreDetailsModalClose}
                          disabled={isSubmitting}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Create  Online Store */}

      <div className="row">
        <Modal
          show={showCreateOnlineStoreDetailsModal}
          onHide={handleCreateOnlineStoreDetailsModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Online Store Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {SingleOnlineStoreData && (
                <Formik
                  initialValues={{
                    OnlineStoreName: "",
                    OnlineStoreImage: null,
                    OnlineStoreUrl: "",
                  }}
                  validationSchema={OnlineStoreSchema}
                  onSubmit={handleSubmitCreateOnlineStore}
                >
                  {({ setFieldValue, values, isSubmitting }) => (
                    <Form>
                      <div>
                        <div>Online Store Name</div>
                        <Field
                          type="text"
                          name="OnlineStoreName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="OnlineStoreName"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div>
                        <div>Online Store Url</div>
                        <Field
                          type="text"
                          name="OnlineStoreUrl"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="OnlineStoreUrl"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mt-2">
                        <div>Online Store Image</div>
                        <input
                          type="file"
                          name="OnlineStoreImage"
                          className="Form_Field"
                          onChange={(event) => {
                            setFieldValue(
                              "OnlineStoreImage",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="OnlineStoreImage"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCreateOnlineStoreDetailsModalClose}
                          disabled={isSubmitting}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Create
                        </Button>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
