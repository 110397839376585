import axios from "axios";
import { Base_Url } from "../constant/Url";

export const getallSubCategory = async () => {
  try {
    const res = await fetch(`${Base_Url}/subcategory/getallsubcategory`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSubCategoriesByCategory = async (categoryname) => {
  try {
    const res = await fetch(
      `${Base_Url}/subcategory/getSubCategoriesByCategory/${categoryname}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const createSubCategory = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/subcategory/createsubcategory`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating sub category:", error);
    throw error;
  }
};

export const deletesingleSubCategory = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/subcategory/deletesingleSubCategory/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleSubCategory = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/subcategory/getsingleSubCategory/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleSubCategory = async (subcategory_id, formData) => {
  try {
    const response = await axios.put(
      `${Base_Url}/subcategory/updatesingleSubCategory/${subcategory_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating Our Brands:", error);
    throw error;
  }
};
