import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { Toaster, toast } from "react-hot-toast";

import { getAllOurBrands } from "../api/OurBrandsApi.js";
import {
  FetchAllSubCategoriesWithCategories,
  getallCategory,
} from "../api/CategoryApi.js";
import {
  createProduct,
  DeleteSingleProduct,
  getallProducts,
} from "../api/ProductsApi.js";

import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url } from "../constant/Url.js";
import { useNavigate } from "react-router-dom";

export const Products = () => {
  const [ProductsData, setProductsData] = useState([]);
  const [AllShopsData, setAllShopsData] = useState([]);
  const [categoryListData, setcategoryListData] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SubCategoryListData, setSubCategoryListData] = useState([]);

  const navigate = useNavigate();

  const FetchAllShops = async () => {
    try {
      const res = await getAllOurBrands();
      if (res?.status === "success") {
        setAllShopsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllProducts = async () => {
    try {
      const res = await getallProducts();
      if (res?.status === "success") {
        setProductsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleShopChange = (e, setFieldValue) => {
    const selectedShop = e.target.value;
    const selectedShopData = AllShopsData?.find(
      (data) => data.OurBrandsName === selectedShop
    );
    setFieldValue("ShopName", selectedShop);
    setFieldValue("ShopNameSlug", selectedShopData?.OurBrandsNameSlug || "");
  };

  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategory = e.target.value;
    const selectedCategoryData = categoryListData?.find(
      (data) => data.CategoryName === selectedCategory
    );
    setFieldValue("CategoryName", selectedCategory);
    setFieldValue(
      "CategoryNameSlug",
      selectedCategoryData?.CategoryNameSlug || ""
    );
    setSelectedCategory(selectedCategoryData?.CategoryNameSlug || "");
  };

  const handleSubCategoryChange = (e, setFieldValue) => {
    const selectedSubCategory = e.target.value;
    const selectedSubCategoryData = SubCategoryListData?.find(
      (data) => data.SubCategoryName === selectedSubCategory
    );
    setFieldValue("SubCategoryName", selectedSubCategory);
    setFieldValue(
      "SubCategoryNameSlug",
      selectedSubCategoryData?.SubCategoryNameSlug || ""
    );
  };

  const getSubCategoryName = async () => {
    const data = {
      CategoryNameSlug: SelectedCategory,
    };
    try {
      const response = await FetchAllSubCategoriesWithCategories(data);
      if (response.status === "success") {
        setSubCategoryListData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object({
    CategoryName: Yup.string().required("Category Name is required"),
    SubCategoryName: Yup.string().required("Sub Category Name is required"),
    ProductName: Yup.string().required("Product Name is required"),
    ProductNameSlug: Yup.string().required("Product Name Slug is required"),
    ProductDescription: Yup.string().required(
      "Product Description is required"
    ),
    ShopName: Yup.string().required("Shop Name is required"),

    ProductPrice: Yup.number().positive("Must be a positive number"),
    discountPercentage: Yup.number()
      .min(0, "Cannot be less than 0")
      .max(100, "Cannot exceed 100"),
    ProductFeatures: Yup.array()
      .of(Yup.string().required("Feature is required"))
      .min(1, "At least one feature is required"),

    ProductTechnicalSpecification: Yup.object({
      Power: Yup.string().required("Power is required"),
      Voltage: Yup.string().required("Voltage is required"),
      Capacity: Yup.string().required("Capacity is required"),
      Material: Yup.string().required("Material is required"),
    }).required("Technical Specifications are required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);

    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);

    formData.append("ProductName", values.ProductName);
    formData.append("ProductNameSlug", values.ProductNameSlug);

    formData.append("ProductDescription", values.ProductDescription);

    formData.append("ShopName", values.ShopName);
    formData.append("ShopNameSlug", values.ShopNameSlug);

    formData.append("ProductPrice", Number(values.ProductPrice));
    formData.append("DiscountPercentage", values.DiscountPercentage);

    values.ProductFeatures.forEach((feature) => {
      formData.append("ProductFeatures", feature);
    });

    Array.from(values.ProductImage || []).forEach((file) => {
      formData.append("ProductImage", file);
    });

    Object.entries(values.ProductTechnicalSpecification).forEach(
      ([key, value]) => {
        formData.append(`${key}`, value);
      }
    );

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createProduct(formData);
      if (res?.data?.status === "success") {
        toast.success("Product Added Successfully!", { duration: 3000 });
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProducts = async (ele) => {
    try {
      const res = await DeleteSingleProduct(ele?._id);
      if (res?.status === "success") {
        toast.success("Product Deleted Successfully!");
        setProductsData(ProductsData.filter((item) => item._id !== ele._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProducts = (data) => {
    navigate(`/products/update/${data?._id}`);
  };

  useEffect(() => {
    FetchAllShops();
    FetchAllCategoryList();
    FetchAllProducts();
  }, []);

  useEffect(() => {
    getSubCategoryName();
  }, [SelectedCategory]);

  return (
    <div>
      <div className="row">
        <Toaster position="top-right" reverseOrder={false} />

        <Formik
          initialValues={{
            CategoryName: "",
            CategoryNameSlug: "",

            SubCategoryName: "",
            SubCategoryNameSlug: "",

            ProductName: "",
            ProductNameSlug: "",

            ProductDescription: "",
            ShopName: "",
            ShopNameSlug: "",

            ProductPrice: "",
            DiscountPercentage: "",

            ProductFeatures: [""],
            ProductImage: null,

            ProductTechnicalSpecification: {
              Power: "",
              Voltage: "",
              Capacity: "",
              Material: "",
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 Form_Field pe-3 p-0">
                      <div className="Form_Field_Heading">
                        Product Name
                        <span>*</span>
                      </div>
                      <Field
                        type="text"
                        name="ProductName"
                        className="Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductName"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-md-6 Form_Field ps-3">
                      <div className="Form_Field_Heading">
                        Product Name Slug
                        <span>*</span>
                      </div>
                      <Field
                        type="text"
                        name="ProductNameSlug"
                        className="Form_Field_Input"
                        onChange={(e) => {
                          const slug = e.target.value
                            .toLowerCase()
                            .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                            .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                          setFieldValue("ProductNameSlug", slug);
                        }}
                      />
                      <ErrorMessage
                        name="ProductNameSlug"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Category Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="CategoryName"
                    className="Form_Field_Input"
                    onChange={(e) => {
                      handleCategoryChange(e, setFieldValue);
                      setFieldValue("CategoryName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Category Name</option>

                    {categoryListData?.map((category) => (
                      <option key={category._id} value={category.CategoryName}>
                        {category.CategoryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="CategoryName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Sub Category Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="SubCategoryName"
                    className="Form_Field_Input"
                    onChange={(e) => {
                      handleSubCategoryChange(e, setFieldValue);
                      setFieldValue("SubCategoryName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Sub Category Name</option>

                    {SubCategoryListData?.map((subcategory) => (
                      <option
                        key={subcategory._id}
                        value={subcategory.SubCategoryName}
                      >
                        {subcategory.SubCategoryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="CategoryName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Shop Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="ShopName"
                    className="Form_Field_Input"
                    onChange={(e) => {
                      handleShopChange(e, setFieldValue);
                      setFieldValue("ShopName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Shop</option>

                    {AllShopsData?.map((shop) => (
                      <option key={shop._id} value={shop.OurBrandsName}>
                        {shop.OurBrandsName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="ShopName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Images
                    <span>*</span>
                  </div>
                  <input
                    name="ProductImage"
                    type="file"
                    multiple
                    onChange={(e) =>
                      setFieldValue("ProductImage", e.currentTarget.files)
                    }
                  />

                  <ErrorMessage
                    name="ProductImage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Price
                    <span>*</span>
                  </div>
                  <Field
                    type="number"
                    name="ProductPrice"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="ProductPrice"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Discount Percentage
                    <span>*</span>
                  </div>
                  <Field
                    type="number"
                    name="DiscountPercentage"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="DiscountPercentage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Description
                    <span>*</span>
                  </div>

                  <Field
                    as="textarea"
                    name="ProductDescription"
                    placeholder="Add a comment..."
                    rows="3"
                    className="Form_Field_Input"
                    required
                  />

                  <ErrorMessage
                    name="ProductDescription"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Features
                    <span>*</span>
                  </div>
                  <FieldArray
                    name="ProductFeatures"
                    render={(arrayHelpers) => (
                      <div>
                        {values.ProductFeatures.map((_, index) => (
                          <div key={index}>
                            <Field
                              name={`ProductFeatures[${index}]`}
                              className="Form_Field_Input_Array"
                            />
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className="ms-3 px-1"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Add Feature
                        </button>
                      </div>
                    )}
                  />
                  <ErrorMessage
                    name="ProductFeatures"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Specifications
                    <span>*</span>
                  </div>
                  <div className="row">
                    <div className="col-md-6 m-0 p-0">
                      <div className="Form_Field_Heading">
                        Power (W)
                        <span>*</span>
                      </div>
                      <Field
                        type="number"
                        name="ProductTechnicalSpecification.Power"
                        className="Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductTechnicalSpecification.Power"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6 p-0 ps-3">
                      <div className="Form_Field_Heading">
                        Voltage (V)
                        <span>*</span>
                      </div>
                      <Field
                        type="number"
                        name="ProductTechnicalSpecification.Voltage"
                        className="Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductTechnicalSpecification.Voltage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-md-6 p-0">
                      <div className="Form_Field_Heading">
                        Capacity (Liter)
                        <span>*</span>
                      </div>
                      <Field
                        type="number"
                        name="ProductTechnicalSpecification.Capacity"
                        className="Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductTechnicalSpecification.Capacity"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="col-md-6 p-0 ps-3">
                      <div className="Form_Field_Heading">
                        Material
                        <span>*</span>
                      </div>
                      <Field
                        type="text"
                        name="ProductTechnicalSpecification.Material"
                        className="Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductTechnicalSpecification.Material"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="Form_Field_Submit_Button">
                <button type="submit">Submit</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="row p-2 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Category Name</th>
              <th>Sub Category Name</th>
              <th>Product Name</th>
              <th>Product Image</th>
              <th>Product Price</th>

              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(ProductsData) && ProductsData.length > 0 ? (
              ProductsData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                .map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.CategoryName}</td>
                    <td>{ele?.SubCategoryName}</td>
                    <td>{ele?.ProductName}</td>

                    <td>
                      <img
                        src={`${Base_Url}${ele?.ProductImage[0]}`}
                        alt={ele?.ProductName}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{ele?.ProductPrice}</td>

                    <td>
                      <td>
                        <div className="d-flex">
                          <div className="action_button_view">
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateProducts(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteProducts(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
