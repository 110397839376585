import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Dropdown } from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import { Base_Url } from "../constant/Url";

export const OrderMgmt = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch orders from the backend
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Base_Url}/order/admin/orders`); // Replace with your API endpoint
      setOrders(response.data.data || []); // Adjust based on response structure
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  // Update order status
  const updateOrderStatus = async (orderId, statusType, value) => {
    try {
      const response = await axios.put(
        `${Base_Url}/order/admin/order/${orderId}`,
        { [statusType]: value }
      );
      alert(response.data.message || "Order updated successfully");
      fetchOrders(); // Refresh order list after update
    } catch (error) {
      console.error("Error updating order:", error);
      alert("Failed to update order status");
    }
  };

  // Delete order
  const deleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(
        `${Base_Url}/order/admin/order/${orderId}`
      );

      console.log(response?.data);

      if (response?.data?.status == "success") {
        toast.success("Order Deleted Successfully", { duration: 3000 });
        fetchOrders();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="container">
      <Toaster />
      <h2>Admin Order Management</h2>
      {loading ? (
        <p>Loading orders...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Order Details</th>

              <th>Payment Status</th>
              <th>Delivery Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {Array.isArray(orders) && orders.length > 0 ? (
              orders?.map((order, index) => (
                <tr key={order._id}>
                  <td>{index + 1}</td>
                  <td>{order._id}</td>
                  <td>
                    <div>Order Id - {order.user?._id || "N/A"}</div>
                    <ul>
                      {order.orderItems.map((item) => (
                        <li key={item._id}>
                          {item.product?.ProductName} ({item.quantity} - Nos.)
                        </li>
                      ))}
                    </ul>

                    <div>
                      Total Price - ₹{" "}
                      {parseInt(order.totalPrice.toFixed(2)).toLocaleString()}{" "}
                      /-
                    </div>
                    <div>
                      Order Data -{" "}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </div>

                    <div>
                      Total quantity -
                      {order.orderItems.reduce(
                        (total, item) => total + item.quantity,
                        0
                      )}{" "}
                      Nos.
                    </div>
                  </td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="info" size="sm">
                        {order.isPaid ? "Paid" : "Not Paid"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            updateOrderStatus(order._id, "isPaid", true)
                          }
                        >
                          Mark as Paid
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            updateOrderStatus(order._id, "isPaid", false)
                          }
                        >
                          Mark as Not Paid
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="info" size="sm">
                        {order.isDelivered ? "Delivered" : "Not Delivered"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            updateOrderStatus(order._id, "isDelivered", true)
                          }
                        >
                          Mark as Delivered
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            updateOrderStatus(order._id, "isDelivered", false)
                          }
                        >
                          Mark as Not Delivered
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => deleteOrder(order._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};
