// 

import axios from "axios";
import { Base_Url } from "../constant/Url";

export const getallSocialMedia = async () => {
  try {
    const res = await fetch(`${Base_Url}/socialMedia/getallsocialmedia`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};