import { Base_Url } from "../constant/Url";

export const getallAboutUsOverView = async () => {
  try {
    const res = await fetch(
      `${Base_Url}/aboutus/overview/getallaboutusoverview`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getallAwards = async () => {
  try {
    const res = await fetch(`${Base_Url}/aboutus/overview/getallawards`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
