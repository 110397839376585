import React, { useEffect, useState } from "react";
import { GrFormAdd } from "react-icons/gr";
import { Toaster, toast } from "react-hot-toast";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye } from "react-icons/io5";
import { Base_Url } from "../constant/Url";
import { getallSocialMedia } from "../api/SocialMediaApi";

export const SocialMedia = () => {
  const [socialMediaData, setSocialMediaData] = useState([]);

  const FetchAllSocialMedia = async () => {
    try {
      const res = await getallSocialMedia();
      console.log(res);
      if (res?.status == "success") {
        setSocialMediaData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllSocialMedia();
  }, []);

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Social Media
              </span>
            </div>
            <div>
              <button
                className="addbutton"
                // onClick={handleTestimonialModalShow}
              >
                Add Testimonials{" "}
                <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
              </button>
            </div>
          </div>
        </div>

        <div className="row py-4">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Image</th>
                <th>Url</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(socialMediaData) && socialMediaData?.length > 0 ? (
                socialMediaData.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.name}</td>

                    <td>
                      <img
                        src={`${Base_Url}${ele?.image}`}
                        alt={ele?.Name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{ele?.link}</td>

                    <td>
                      <div className="d-flex">
                        <div
                          //   onClick={() => handleSingleTestimonial(ele)}
                          className="action_button_view"
                        >
                          <IoEye />
                        </div>
                        <div
                          className="px-2 action_button_update"
                          //   onClick={() => handleUpdateTestimonial(ele)}
                        >
                          <HiPencilSquare className="text-success" />
                        </div>
                        <div
                          className="action_button_delete"
                          //   onClick={() => handleDeleteTestimonial(ele)}
                        >
                          <IoTrashBinOutline className="text-danger" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
