import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { setEncryptedCookie } from "../utils/Utils";
import { useNavigate } from "react-router-dom";
import { loginWithPhoneNumberApi,otpVerifiedApi } from "../api/LoginAPI";
import "../styles/Login.css"
// import { loginWithPhoneNumberApi, otpVerifiedApi } from "../api/UserApi";

const validationSchema = Yup.object({
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  otp: Yup.string().when("isOtpSent", {
    is: true,
    then: Yup.string()
      .required("OTP is required")
      .matches(/^[0-9]{5}$/, "OTP must be 6 digits"),
  }),
});

export const Login = () => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const navigate = useNavigate();

  const handleSendOTP = async (values) => {
    try {
      setMobileNumber(values?.mobile);
      const res = await loginWithPhoneNumberApi({ mobile: values.mobile }); // Send the mobile value to the API
      console.log(res);
      if (res?.status === "success") {
        setIsOtpSent(true);

        toast.success("OTP Sent Successfully", { autoClose: 4000 });
      } else {
        console.log("Error sending OTP", res.error);
        toast.error("Failed to send OTP", {
          autoClose: 4000,
        });
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleVerifyOtp = async (values) => {
    try {
      const res = await otpVerifiedApi({
        mobile: mobileNumber,
        otp: values?.otp,
      });

      if (res?.status === "success") {
        toast.success("Login Successfully", { duration: 3000 });
        setEncryptedCookie("UserData", {
          mobile: res?.data?.mobile,
          token: res?.token,
          status: res?.status,
          userId: res?.data?._id,
        });
        setTimeout(() => {
          navigate("/");
          window.location.reload(); // Refresh after navigating to home page
        }, 3100);
      } else {
        console.log("OTP verification failed", res?.message || res?.error);
        toast.error("Incorrect OTP!", {
          autoClose: 4000,
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <>
      <Toaster />
      <section className="login_page_section">
        <div className="container my-4">
          <div className="row justify-content-center">
            <div
              className="col-lg-6 col-12 p-3"
              style={{ border: "1px solid gray" }}
            >
              <div className="login_page_div_main">
                <h3 className="text-center">Admin Login</h3>
                <Formik
                  initialValues={{
                    mobile: "",
                    otp: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={isOtpSent ? handleVerifyOtp : handleSendOTP}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <div className="col-md-12 mt-1 ">
                        <label style={{ fontWeight: "600" }}>
                          Mobile <span style={{ color: "red" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="mobile"
                          className="p-0 px-2 m-0"
                          placeholder="Mobile Number"
                          style={{ height: "40px", width: "100%" }}
                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {isOtpSent && (
                        <div className="col-md-12 mt-2">
                          <label>
                            OTP <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            type="text"
                            name="otp"
                            className="p-0 px-2 m-0"
                            placeholder="Enter OTP"
                            style={{ height: "40px", width: "100%" }}
                          />
                          <ErrorMessage
                            name="otp"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}

                      <div style={{ padding: "5px 15px" }}>
                        <span> By signing up, you agree to our </span>
                        <a href="/termsconditions" className="px-1">
                          terms & conditions
                        </a>
                        <span>and</span>
                        <a href="/privacypolicy" className="px-1">
                          privacy policy
                        </a>
                      </div>

                      <div className="generating_btn mt-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="common_button"
                        >
                          {isOtpSent ? "Verify OTP" : "Send OTP"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
