import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Toaster, toast } from "react-hot-toast";
import {
  createCategory,
  DeleteSingleCategory,
  getallCategory,
  getSingleCategory,
  UpdateSingleCategory,
} from "../api/CategoryApi";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import Modal from "react-bootstrap/Modal";
import { GrFormAdd } from "react-icons/gr";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Base_Url } from "../constant/Url";

const CategorySchema = Yup.object().shape({
  CategoryName: Yup.string().required("Category Name is required"),
  CategoryNameSlug: Yup.string()
    .matches(
      /^[a-z-]+$/, // No numbers, spaces, or special characters except dashes
      "Slug can only contain lowercase letters and dashes (-), numbers and special characters are not allowed"
    )
    .required("Category Name Slug is required"),
  CategoryImage: Yup.string().required("Category Image is required"),
});

export const Category = () => {
  const [categoryData, setcategoryData] = useState([]); // Renamed for clarity
  const [SingleCategoryData, setSingleCategoryData] = useState([]); // Renamed for clarity

  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState("");

  const handleCreateCategoryModalClose = () =>
    setShowCreateCategoryModal(false);
  const handleCreateCategoryModalShow = () => setShowCreateCategoryModal(true);

  const [showSingleCategoryModal, setShowSingleCategoryModal] = useState("");

  const handleSingleCategoryModalClose = () =>
    setShowSingleCategoryModal(false);
  const handleSingleCategoryModalShow = () => setShowSingleCategoryModal(true);

  const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState("");

  const handleUpdateCategoryModalClose = () =>
    setShowUpdateCategoryModal(false);
  const handleUpdateCategoryModalShow = () => setShowUpdateCategoryModal(true);

  const FetchAllCategory = async () => {
    try {
      const res = await getallCategory();
      console.log(res);
      if (res?.status === "success") {
        setcategoryData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllCategory();
  }, []);

  const handleSingleCategory = async (ele) => {
    try {
      const res = await getSingleCategory(ele?._id);
      console.log(res, "getSingleCategorygetSingleCategorygetSingleCategory");
      if (res?.status === "success") {
        handleSingleCategoryModalShow();
        setSingleCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCategory = async (ele) => {
    try {
      const res = await getSingleCategory(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        handleUpdateCategoryModalShow();
        setSingleCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);

    // Only append CategoryImage if a new file is selected
    if (
      values.CategoryImage &&
      values.CategoryImage !== SingleCategoryData.CategoryImage
    ) {
      formData.append("CategoryImage", values.CategoryImage);
    }

    try {
      const res = await UpdateSingleCategory(SingleCategoryData._id, formData);
      console.log(res);
      if (res?.data?.status === "success") {
        toast.success("Category Updated Successfully!", { duration: 3000 });
        FetchAllCategory();
        handleUpdateCategoryModalClose();
      }
    } catch (error) {
      console.error("Error updating Category:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteCategory = async (ele) => {
    try {
      const res = await DeleteSingleCategory(ele?._id);
      if (res?.status === "success") {
        toast.success("Product Deleted Successfully!");
        setcategoryData(categoryData.filter((item) => item._id !== ele._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitCreateCategory = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryImage", values.CategoryImage);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createCategory(formData);
      if (res?.data?.status === "success") {
        FetchAllCategory();
        toast.success("Category Added Successfully!",{duration:3000});
        handleCreateCategoryModalClose();
      }
    } catch (error) {
      console.error("Error creating testimonial:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                Category Management
              </span>
            </div>
            <div>
              <button
                className="addbutton"
                onClick={handleCreateCategoryModalShow}
              >
                Add Category
                <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
              </button>
            </div>
          </div>
        </div>

        <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Category Name</th>
                <th>Category Name Slug</th>
                <th>Category Image</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(categoryData) && categoryData.length > 0 ? (
                categoryData
                  .filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>
                      <td>{ele?.CategoryName}</td>
                      <td>{ele?.CategoryNameSlug}</td>
                      <td>
                        <img
                          src={`${Base_Url}${ele?.CategoryImage}`}
                          alt=""
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>
                        <td>
                          <div className="d-flex">
                            <div
                              onClick={() => handleSingleCategory(ele)}
                              className="action_button_view"
                            >
                              <IoEye />
                            </div>
                            <div
                              className="px-2 action_button_update"
                              onClick={() => handleUpdateCategory(ele)}
                            >
                              <HiPencilSquare className="text-success" />
                            </div>
                            <div
                              className="action_button_delete"
                              onClick={() => handleDeleteCategory(ele)}
                            >
                              <IoTrashBinOutline className="text-danger" />
                            </div>
                          </div>
                        </td>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* Create Category */}

        <div className="row">
          <Modal
            show={showCreateCategoryModal}
            onHide={handleCreateCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  CategoryName: "",
                  CategoryImage: "",
                  CategoryNameSlug: "",
                }}
                validationSchema={CategorySchema}
                onSubmit={handleSubmitCreateCategory}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <div className="mt-2">
                      <div>Category Name</div>
                      <Field
                        type="text"
                        name="CategoryName"
                        className="Form_Field"
                      />
                      <ErrorMessage
                        name="CategoryName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mt-2">
                      <div>Category Name Slug</div>
                      <Field
                        type="text"
                        name="CategoryNameSlug"
                        className="Form_Field"
                        onChange={(e) => {
                          const slug = e.target.value
                            .toLowerCase()
                            .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                            .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                          setFieldValue("CategoryNameSlug", slug);
                        }}
                      />
                      <ErrorMessage
                        name="CategoryNameSlug"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div>Category Image</div>
                      <input
                        type="file"
                        name="CategoryImage"
                        className="Form_Field"
                        onChange={(event) => {
                          setFieldValue(
                            "CategoryImage",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="CategoryImage"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCreateCategoryModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>

        {/* View Single Online Store */}

        <div className="row">
          <Modal
            show={showSingleCategoryModal}
            onHide={handleSingleCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Single Online Store Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="modaldata d-flex pb-3">
                  <span>CategoryName : </span>
                  <div>{SingleCategoryData?.CategoryName}</div>
                </div>

                <div className="modaldata d-flex pb-3">
                  <span>Category Image : </span>
                  <div>
                    <img
                      src={`${Base_Url}${SingleCategoryData?.CategoryImage}`}
                      alt=""
                      style={{ width: "100%", height: "50px" }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Update Category */}

        <div className="row">
          <Modal
            show={showUpdateCategoryModal}
            onHide={handleUpdateCategoryModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Category Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Formik
                  initialValues={{
                    CategoryName: SingleCategoryData.CategoryName || "",
                    CategoryNameSlug: SingleCategoryData.CategoryNameSlug || "",
                    CategoryImage: SingleCategoryData.CategoryImage || null,
                  }}
                  validationSchema={CategorySchema}
                  onSubmit={handleSubmitUpdate}
                >
                  {({ setFieldValue, values, isSubmitting }) => (
                    <Form>
                      <div>
                        <div>Category Name</div>
                        <Field
                          type="text"
                          name="CategoryName"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="CategoryName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div>
                        <div>Category Name Slug</div>
                        <Field
                          type="text"
                          name="CategoryNameSlug"
                          className="Form_Field"
                        />
                        <ErrorMessage
                          name="CategoryNameSlug"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mt-2">
                        <div>Category Image</div>
                        <input
                          type="file"
                          name="CategoryImage"
                          className="Form_Field"
                          onChange={(event) => {
                            setFieldValue(
                              "CategoryImage",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <ErrorMessage
                          name="CategoryImage"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleUpdateCategoryModalClose}
                          disabled={isSubmitting}
                        >
                          Close
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </Form>
                  )}
                </Formik>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};
