import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { GrFormAdd } from "react-icons/gr";
import { createDeals, DeleteDeal, getAllDeals } from "../api/DealsApi";
import Table from "react-bootstrap/Table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

export const Deal = () => {
  const [DealsData, SetDealsData] = useState(null);

  const ConvertDateTimeFormat = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const amOrPm = date.getHours() < 12 ? "AM" : "PM";
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;

    return `${day} ${month} ${year} at ${formattedTime}`;
  };

  const fetchAllDeals = async () => {
    try {
      const response = await getAllDeals();
      console.log(response);
      if (response?.status === "success") {
        SetDealsData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDeals = async (data) => {
    try {
      const response = await DeleteDeal(data);
      if (response?.status === "success") {
        toast.success("Deal Deleted Successfully", { duration: 3000 });
        fetchAllDeals();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllDeals();
  }, []);

  const initialValues = {
    dealName: "",
    // products: "",
    startTime: "",
    endTime: "",
    dealType: "",
  };

  const validationSchema = Yup.object().shape({
    dealName: Yup.string().required("Deal Name is required"),
    startTime: Yup.string()
      .required("Start Time is required")
      .test(
        "is-today-or-later",
        "Start date cannot be in the past",
        (value) => {
          return dayjs(value).isSameOrAfter(dayjs(), "minute");
        }
      ),
    endTime: Yup.string()
      .required("End Time is required")
      .test(
        "is-after-start",
        "End date must be after the start date",
        function (value) {
          const { startTime } = this.parent;
          return dayjs(value).isAfter(dayjs(startTime), "minute");
        }
      ),
    dealType: Yup.string().required("Deal Type is required"),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const formData = {
        dealName: values.dealName,
        products: values.products,
        startTime: dayjs(values.startTime).format("DD-MM-YYYY h:mmA"),
        endTime: dayjs(values.endTime).format("DD-MM-YYYY h:mmA"),
        dealType: values.dealType,
      };

      console.log(formData);

      const response = await createDeals(formData); // API call
      if (response?.status === "success") {
        toast.success("Deal Created Successfully", { duration: 3000 });
        actions.resetForm();
        fetchAllDeals(); // Refresh the deal list
      } else {
        toast.error(response?.message || "Failed to create deal", {
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
    actions.setSubmitting(false);
  };

  return (
    <div className="container">
      <Toaster />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Deals Management
            </span>
          </div>
          <div></div>
        </div>
      </div>

      <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Deal Name</th>
              <th>Deals Start Time</th>
              <th>Deals End Time</th>
              <th>Deals Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(DealsData) && DealsData.length > 0 ? (
              DealsData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.dealName}</td>
                  <td>{ConvertDateTimeFormat(ele?.startTime)}</td>
                  <td>{ConvertDateTimeFormat(ele?.endTime)}</td>
                  <td onClick={() => handleDeleteDeals(ele?._id)}>
                    {ele?.status}
                  </td>
                  <td onClick={() => handleDeleteDeals(ele?._id)}>Delete</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="row">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleChange, isSubmitting }) => (
            <Form>
              <div className="form_main_div">
                <div className="row">
                  <div className="col-6">
                    <label>Deal Name</label>
                    <Field
                      type="text"
                      name="dealName"
                      className="Form_Field"
                      placeholder="Enter Deal Name"
                    />
                    <ErrorMessage
                      name="dealName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label>Products</label>
                    <Field
                      type="text"
                      name="products"
                      className="Form_Field"
                      placeholder="Enter Products"
                    />
                    <ErrorMessage
                      name="products"
                      component="div"
                      className="text-danger"
                    />
                  </div> */}
                  <div className="col-6">
                    <label>Start Time</label>
                    <Field
                      type="datetime-local"
                      name="startTime"
                      className="form-control"
                      min={dayjs().format("YYYY-MM-DDTHH:mm")}
                    />
                    <ErrorMessage
                      name="startTime"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-6">
                    <label>End Time</label>
                    <Field
                      type="datetime-local"
                      name="endTime"
                      className="form-control"
                      min={
                        values.startTime || dayjs().format("YYYY-MM-DDTHH:mm")
                      }
                    />
                    <ErrorMessage
                      name="endTime"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-6">
                    <label>Deal Type</label>
                    <Field
                      type="text"
                      name="dealType"
                      className="Form_Field"
                      placeholder="Enter Deal Type"
                    />
                    <ErrorMessage
                      name="dealType"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="addbutton bg-white mt-3"
                  disabled={isSubmitting}
                >
                  Create Deal
                  <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
